import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { profileApiCachedUrl } from "../../../service-wrappers/profile-api";
import ServerAutoSuggest from "../ServerAutoSuggest";

export const fetchSubstate = (substateId) => {
  return fetch(profileApiCachedUrl("substates-id", substateId)).then((R) =>
    R.json()
  );
};

const dflt = [];

function getConfigs({ country, includeOnly }) {
  const config = {
    dflt: (includeOnly ? dflt.filter(includeOnly) : dflt).filter(
      (r) => !country || country === r.country
    ),

    fetch: fetchSubstate,

    // Trigger suggestions
    getSuggestionValue: (suggestion) =>
      `${suggestion.name}, ${suggestion.country}`,
    // Render Each Option
    renderSuggestion: (suggestion) =>
      `${suggestion.name}, ${suggestion.country}`,
    api: (itrimValue) =>
      country
        ? profileApiCachedUrl(
            "substates-country-prefix",
            country,
            itrimValue.substring(0, 1)
          )
        : profileApiCachedUrl("substates-prefix", itrimValue.substring(0, 1)),
    data2results: (data, itrimValue, getSuggestionValue, dflt1) => {
      const dfltIds = dflt1.map((r) => r.id);
      const filterFn = (f, itrimValue1) =>
        getSuggestionValue(f).toLowerCase().startsWith(itrimValue1);
      return [
        ...dflt1.filter((f) => filterFn(f, itrimValue)),
        ...data.results
          .filter(
            (f) =>
              (!includeOnly || includeOnly(f)) &&
              f.active &&
              filterFn(f, itrimValue) &&
              !dfltIds.includes(f.id)
          )
          .filter((i, idx) => idx < 20),
      ];
    },
  };

  return config;
}

const Substate = ({ country, includeOnly, ...restprops }) => {
  return (
    <ServerAutoSuggest
      {...restprops}
      config={useMemo(
        () => getConfigs({ country, includeOnly }),
        [country, includeOnly]
      )}
    />
  );
};

Substate.propTypes = {
  country: PropTypes.string,
  includeOnly: PropTypes.func,
};

Substate.defaultProps = {
  country: "",
  includeOnly: null,
};

export default Substate;
