/** @jsx jsx */
import { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { jsx, Button, Text } from "theme-ui";
import PropTypes from "prop-types";

// function generateDownload(canvas, crop) {
//     if (!crop || !canvas) {
//         return;
//     }

//     canvas.toBlob(
//         (blob) => {
//             const previewUrl = window.URL.createObjectURL(blob);

//             const anchor = document.createElement('a');
//             anchor.download = 'cropPreview.png';
//             anchor.href = URL.createObjectURL(blob);
//             anchor.click();

//             window.URL.revokeObjectURL(previewUrl);
//         },
//         'image/png',
//         1
//     );
// }

function ImageCrop({ name, onChange, initialCrop, btnText, noForceOpen }) {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState(initialCrop);
  const [completedCrop, setCompletedCrop] = useState(null);
  const fileRef = useRef();
  const [ssx, setScaleX] = useState(0);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
    const scaleX = img.naturalWidth / img.width;
    setScaleX(scaleX);

    const aspect = 8 / 9;
    let width =
      img.naturalWidth < 400 * 1.11 ? img.naturalWidth : img.width * 0.9;
    let height =
      img.naturalHeight < 450 * 1.11 ? img.naturalHeight : img.height * 0.9;
    if (width / aspect < height) {
      height = width / aspect;
    } else {
      width = height * aspect;
    }
    const y = (img.height - height) / 2;
    const x = (img.width - width) / 2;

    setCrop({
      width,
      height,
      x,
      y,
      aspect,
    });
    setCompletedCrop({
      width,
      height,
      x,
      y,
      aspect,
    });
    return false;
  }, []);

  useEffect(() => {
    if (!noForceOpen) {
      fileRef.current.click();
    }
  }, [noForceOpen]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop1 = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    // const pixelRatio = window.devicePixelRatio;

    canvas.width = 400;
    canvas.height = 450;

    // ctx.setTransform(400 / (crop.width * scaleX) , 0, 0, 450 / (crop.height * scaleY), 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop1.x * scaleX,
      crop1.y * scaleY,
      crop1.width * scaleX,
      crop1.height * scaleY,
      0,
      0,
      400,
      450
    );
    onChange(canvas.toDataURL("image/jpeg"), name);
  }, [completedCrop, name, onChange]);

  return (
    <div>
      <div>
        <input
          sx={{ display: "none" }}
          ref={fileRef}
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
      </div>

      <div
        sx={{ textAlign: "center", border: "1px solid", borderColor: "muted" }}
      >
        <Button
          variant="secondaryFit"
          sx={{ my: 1 }}
          onClick={() => fileRef.current.click()}
          type="button"
        >
          {btnText}
        </Button>

        {!!ssx && (
          <div>
            <Text variant="description" sx={{ mt: 1 }}>
              Adjust the crop area to select the front view full face in the
              picture
            </Text>
          </div>
        )}
        <ReactCrop
          sx={{ m: 1 }}
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
          minWidth={Math.round(400 / ssx)}
          ruleOfThirds
        />
        <div sx={{ m: 1, color: "text" }}>
          <Text variant="h4">Selected Photo</Text>
        </div>

        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          sx={{
            border: "solid 1px #777",
            width: 200,
            height: 225,
          }}
        />
      </div>
    </div>
  );
}

ImageCrop.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  initialCrop: PropTypes.shape({}),
  btnText: PropTypes.string,
  noForceOpen: PropTypes.bool,
};

ImageCrop.defaultProps = {
  initialCrop: { aspect: 8 / 9 },
  btnText: "Select Picture",
  noForceOpen: false,
};

export default ImageCrop;
