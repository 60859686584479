/** @jsx jsx */
import { jsx, Button, Box, Flex } from "theme-ui";
import PropTypes from "prop-types";
import useMyBoolean from "../../../hooks/useMyBoolean";
import { containerStyles } from "../../../utils/fabricStyles";
import ImageCrop from "../../ImageCrop";

function RenderPhotoFieldForReading({
  photo_url,
  imgProps,
  onSetEditMode,
  readOnly,
  disabled,
}) {
  const [noPhoto, { setTrue: setNoPhoto }] = useMyBoolean(false);
  const isNoPhoto = noPhoto || !photo_url;

  const showChangeBtns = !readOnly;

  return (
    <Box>
      {!isNoPhoto && (
        <Flex
          sx={{
            width: 120,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img {...imgProps} src={photo_url} alt=" " onError={setNoPhoto} />
          {showChangeBtns && (
            <div sx={{ m: 1 }}>
              <Button
                variant="mutedFit"
                onClick={onSetEditMode}
                disabled={disabled}
              >
                change photo
              </Button>
            </div>
          )}
        </Flex>
      )}
      {isNoPhoto && (
        <div
          sx={{
            ...containerStyles.flexcenter,
            bg: "secondary",
            width: "120px",
            height: "135px",
          }}
        >
          {showChangeBtns ? (
            <Button
              variant="mutedFit"
              sx={{ color: "white" }}
              onClick={onSetEditMode}
              disabled={disabled}
            >
              add photo
            </Button>
          ) : (
            <div>No Photo</div>
          )}
        </div>
      )}
    </Box>
  );
}

RenderPhotoFieldForReading.propTypes = {
  photo_url: PropTypes.string,
  onSetEditMode: PropTypes.func,

  showChanged: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  imgProps: PropTypes.shape({}),
};

RenderPhotoFieldForReading.defaultProps = {
  photo_url: undefined,
  onSetEditMode: undefined,
  imgProps: {
    width: "120",
  },
  disabled: undefined,
  readOnly: undefined,
  showChanged: false,
};

function RenderPhotoField(props) {
  const {
    readOnly,
    editOnly,
    disabled,
    name,
    value,
    onChange,
    photo_url,
    modes,
    noForceOpen,
  } = props;

  return (
    <div>
      {modes.includes("read") && !editOnly && (
        <RenderPhotoFieldForReading {...props} />
      )}
      {modes.includes("edit") && !readOnly && !disabled && (
        <ImageCrop
          btnText={value ? "select another photo" : "select photo"}
          name={name}
          value={value}
          onChange={onChange}
          photo_url={photo_url}
          noForceOpen={noForceOpen}
        />
      )}
    </div>
  );
}

RenderPhotoField.propTypes = {
  photo_url: PropTypes.string.isRequired,
  onSetEditMode: PropTypes.func,
  modes: PropTypes.shape([]),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  editOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  imgProps: PropTypes.shape({}),
  noForceOpen: PropTypes.bool,
};

RenderPhotoField.defaultProps = {
  value: undefined,
  onChange: undefined,
  imgProps: {
    width: "120",
  },
  disabled: undefined,
  readOnly: undefined,
  editOnly: undefined,
  modes: ["read", "edit"],
  onSetEditMode: undefined,
  noForceOpen: false,
};

export default RenderPhotoField;
